.twoButton{
  font-size: .28rem;
  .awardIsEntryButton{
    div:first-child{
      margin-left: .28rem;
    }
    div{
      margin-left: .16rem;
    }
    .checkStyle{
      display: inline-block;
      width: 1.6rem;
      height: .6rem;
      text-align: center;
      line-height: .6rem;
      border-radius: .3rem;
      font-size: .28rem;
      background:#2C68FF;
      color: #ffffff;
    }
    .notCheckStyle{
      display: inline-block;
      width: 1.6rem;
      height: .6rem;
      text-align: center;
      line-height: .6rem;
      border-radius: .3rem;
      font-size: .28rem;
      background:#F5F5F5;
      color: "#4D4D4D"
    }
  }
}
@primary-color: #1DA57A;