html,
body {
  height: 100%;
  width: 100%;   
  background-color: #F7FBFF;
}
:global{
  #root {
    width: 100%;
    // height: 100%;
  }
  .App {
    width: 100%;
    // height: 100%;
  }
}
/*chrome浏览器中自带input样式input:-internal-autofill-selected  */
input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-transition-delay: 111111s;
        -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
    }
input {-webkit-appearance:none; }
.flexBoxBetween{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexBoxAround{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flexBoxCenter{
  display: flex;
  justify-content: center ;
  align-items: center;
}
// html {
//   overflow-y: scroll;
// }
// :root {
//   overflow-y: auto;
//   overflow-x: hidden;
// }
// :root body {
//   position: absolute;
// }
// Firefox
.element { overflow: -moz-scrollbars-none; }
/* IE 10+ */
.element { -ms-overflow-style: none; }
/* chrome 和Safari */
.element::-webkit-scrollbar { width: 0 !important }
.adm-input{
    font-weight: normal;
}


@primary-color: #1DA57A;