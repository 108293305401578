.myAward{
  .adm-list-item-content{
    width: 100% ;
    padding-left: 12px;
  }
  .picture{
    width: .7rem;
    height: .7rem
  }
  .listItem{
    // margin-top: ".2rem";
    position: relative;
    width: 100%;
    height: 1.3rem;
    font-size: .34rem;
    display: flex;
    align-items: center;
    justify-content: center
  }
  .styleDiv{
    height: .2rem;
    background: #F7FBFF
  }
  .awardPolicyEntry{
    margin: .26rem .57rem;
    height: .44rem;
    .awardPolicyEntryIcon{
      display: inline-block;
      width: .44rem;
      height: .44rem;
      font-size: .44rem;
      color: #808080;
      margin-bottom: .1rem;
    }
    .awardPolicyEntryWords{
      display: inline-block;
      height: .44rem;
      margin-left: 0.09rem;
      font-size: .26rem;
      color: #666666;
      text-align: center;
      line-height: .44rem;
    }
  }
  .publicList{
    margin-top: -.15rem;
    .adm-list-card{
      background: none;
      // padding: 0rem .5rem;
      .adm-list-item-content{
        margin-left: .3rem;
        font-size: .34rem;
        .adm-list-item-content-main{
          margin-left: .3rem;
          padding-top: .2rem;
          color: #1A1A1A;
          // font-weight: 400;
        }
      }
    }
  }
}
@primary-color: #1DA57A;