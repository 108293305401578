
.myInfoDetails{
  width: 100%;
  overflow-x:hidden;
  .adm-form .adm-list {
    --padding-left: none !important;
  }
  .adm-list{
    // --prefix-width: 6.4em !important;
    --align-items:stretch !important;
    font-size: .28rem;
    background: #FFFFFF;
    box-shadow: 0 -.06rem .12rem 0 rgba(126, 137, 164, 0.06);
    border-radius: .3rem .3rem 0 0;
    .adm-list-inner{
      padding: .2rem .3rem;
      .adm-list-item-content-main{
        .adm-text-area{
          font-size: .28rem;
          min-height: .6rem !important;
        }
        .twoButton{
          width: 100%;
          .awardIsEntryButton{
            position: relative;
            left: -.4rem;
            width: 100%;
          }
        }
        .pickerValues{
          display: inline-block;
          padding: .1rem 0;
        }
        .pickerTips{
          display: inline-block;
          padding: .1rem 0;
          color: #cccccc;
        }
        .adm-list-item-title{
          font-size: .28rem;
        }
        .adm-input-wrapper{
          input:-internal-autofill-selected{
            background-color: -internal-light-dark(#FFFFFF, rgba(70, 90, 126, 0.4)) !important;
          }
          .adm-input{
            font-size: .28rem;
          }
        }
        .adm-list-item-description{
          .adm-form-item-footer{
            font-size: .28rem;
          }
        }
      }
      .adm-list-item-description{
        .adm-form-item-footer{
          font-size: .28rem;
        }
      }
      .adm-list-item-description{
        .adm-form-item-footer{
          font-size: .28rem;
        }
      }
    }
  }
  .adm-form-footer{
    .adm-button{
      font-size: .32rem;
    }
  }
  // .adm-form .adm-list{
  //   --padding-left:12px;
  // }
  .buttonForm{
    .adm-list-item{
      width: 100%;
    }
  }
  .headImg{
    position: relative;
    left: 2.5rem;
    top: .3rem;
    z-index: 9;
    .imgUploadSign{
      // display: inline-block;
      position: relative;
      // top: -.3rem;
      // left:-.4rem;
      width: 1.5rem;
      height: 1.5rem;
      .UploadSign{
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        // display: inline-block;
      }
      .UploadSignInput{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        opacity: 0;
      }
    }
    .imgUploadText{
      width: 1.5rem;
      padding-top: .16rem;
      font-size: .26rem;
      text-align: center;
      color: #B3B5B9;
    }
  }
}
.twoButton{
  font-size: .28rem;
  .awardIsEntryButton{
    div:first-child{
      margin-left: .28rem;
    }
    div{
      margin-left: .16rem;
    }
    .checkStyle{
      display: inline-block;
      width: 1.2rem;
      height: .5rem;
      text-align: center;
      line-height: .5rem;
      border-radius: .3rem;
      font-size: .28rem;
      background:#2C68FF;
      color: #ffffff;
    }
    .notCheckStyle{
      display: inline-block;
      width: 1.2rem;
      height: .5rem;
      text-align: center;
      line-height: .5rem;
      border-radius: .3rem;
      font-size: .28rem;
      background:#F5F5F5;
      color: "#4D4D4D"
    }
  }
}
.myInfoItem{
  width: 100%;
  overflow-x:hidden;
  position:relative ;
  top: -.6rem;
  font-size: .3rem;
  .adm-input-wrapper{
    --disabled-color:#686B73 !important;}
  .baseDivBoxfirst{
      height: 1rem;
    }
  .submitButton{
    border-radius: .48rem;
    margin-bottom: 1rem ;
  }
  .distance{
    padding: .2rem 0;
  }
  // .publicFormItem{
    padding-top: .1rem;
    padding-bottom: .1rem;
    .echoData{
      color: #686B73;
    }
    .baseProStar{
      margin-left: -.08rem;
      margin-right: .08rem;
    }
      // margin-top: 0rem;
      .healthFileBox > div{
        padding-bottom: .24rem;
        font-size: .28rem;
        color: #686B73;
      }
      .healthFileBoxTitle{
        padding-top: .1rem;
        font-size: .28rem;
        color: #363A44;
        font-weight: 500;
      }
      .healthFileBody{
        padding-right: .5rem;
      }
  }


.adm-dialog-wrap{
  border-radius:0;
  box-shadow: none;
  .DialogButtom:first-child{
    margin-right: .8rem;
  }
  .DialogButtom{
    display: inline-block;
    width: 40%;
  }
  .adm-list{
    .adm-list-inner{
      padding: 0 0;}
  }
  .adm-auto-center{
    .adm-auto-center-content{
      font-size: .28rem;
    }
  }
}
@primary-color: #1DA57A;