#root{
  width: 100%;
  height: 100%;
}
.App{
  width: 100%;
  height: 100%;
}
.mockExamination{
  position: relative;
  padding-top: 1.18rem;
  width: 100%;
  height: 100%;
  .chatButton{
    border-radius: .48rem;
    // margin-bottom: 1rem ;
    margin-top:.47rem;
    font-size: .32rem;
  }
  .background{
    width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
  }
  .topicTop{
    position: relative;
    margin: 0 .3rem;
    width: 6rem;
    height: 5rem;
    background: #ffffff;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.07);
    border-radius: .2rem;
    border: 1px solid #EDEDED;
  }
  .topicBottom{
    position: relative;
    margin: 0 .3rem;
    width: 6rem;
    // height: 40%;
    min-height: 3rem;
    background: #ffffff;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.07);
    border-radius: .2rem;
    border: 1px solid #EDEDED;
  }
  .topicTop{
    .title{
      width: 6rem;
      height: 1.2rem;
      background:url(../../../image/mockExamination/bg.png) no-repeat;
      background-size:cover;
      font-size: .34rem;
      font-weight: 600;
      color: #333333;
      line-height: .42rem;
      padding-top: .36rem;
      text-align:center;
      border-radius: 0.2rem .2rem 0 0;
    }
    .examinationTips{
      overflow-y:auto;
      margin: .2rem .3rem;
      width: 5.6rem;
      height: 2rem;
      font-size: .28rem;
      color: #333333;
      line-height: 28px;
    }
    .start{
      width: 4rem;
      height: .8rem;
      background: #2C68FF;
      box-shadow: 0px 7px 12px -2px rgba(42, 30, 0, 0.02);
      border-radius: .5rem;
      color: #ffffff;
      font-weight: 600;
      text-align: center;
      line-height: .8rem;
      font-size: .3rem;
      margin: .3rem .95rem;
    }
  }
  .topicBottom{
    margin-top: .3rem;
    padding: .3rem;
    // height: 40%;
    // overflow-y:auto;
    margin-bottom: .6rem;
    .historyTitle{
      font-size: .3rem;
      vertical-align: bottom;
      .time{
        display: inline-block;
        width: .56rem;
        height: .56rem;
        font-weight: 600;
        color: #333333;
      }
    }
    .historyContent{
      height: .85rem;
      margin: .3rem 0;
      background: #F5F7FB;
      padding: .22rem .3rem;
      border-radius: 10px;
      border: 1px solid #EDEDED;
      .historyScore{
        float: left;
        font-size: .36rem;
        color: #34E4AA;
        line-height: .42rem;
      }
      .historyTime{
        position: absolute;
        right: 3rem;
        font-size: .24rem;
        color: #808080;
        line-height: .33rem;
      }
      .historyDetail{
        float: right;
        font-size: .24rem;
        color: #2C68FF;
        line-height: .33rem;
      }
      .clearfloat{
        clear:both
      }
    }
  }
}
@primary-color: #1DA57A;