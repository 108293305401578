.questionType{
  padding: .2rem .3rem;
  background: #ffffff;
  .questionTypeTop{
    font-size: .3rem;
    font-weight: 600;
    color: #333333;
    line-height: .45rem;
    // margin-top: .3rem;
  }
  .adm-list{
    border: none;
    // margin-top: .3rem;
  }
  .adm-list-item{
    padding: 0 .2rem 0 .3rem;
    background: #F5F7FB;
    border-radius: 10px;
    margin-bottom: .16rem;
  }
  .adm-list-item-content-main{
    font-size: .28rem;
    color: #1A1A1A;
    line-height: .4rem;
    .typeNumber{
      margin-left: .1rem;
      color: #2C68FF;
      line-height: .32rem;
    }
  }
  .adm-list-item-content-arrow{
    .antd-mobile-icon{
      font-size: .28rem;
    }
  }
}
@primary-color: #1DA57A;