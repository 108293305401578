.talentResume{
  padding: 0 .3rem;
  padding-bottom: .5rem;
  background: #ffffff;
  .talentResumeLineOne{
    padding: .3rem 0;
    .lineOneLf{
      .adm-image{
        border-radius: 50%;
      }
      
    }
    .lineOneRg{
      padding-left:.2rem ;
      width: 5.4rem;
      .name{
        font-size: .36rem;
        color:#1A1A1A ;
        font-weight: 500;
        line-height: .5rem;
      }
      .condition{
        font-size: .26rem;
        color: #4D4D4D;
        line-height: .36rem;
        padding-top: .18rem;
        .sex{
          padding-right: .18rem;
        }
        .age{
          padding: 0 .18rem;
          border-right: solid 1px #E6E6E6;
          border-left: solid 1px #E6E6E6;
        }
        .education{
          padding-left: .18rem;
        }
      }
      .telphoneDetails{
        font-size: .26rem;
        line-height: .3rem;
        margin-top: .16rem;
        .telImg{
          width: .26rem;
          height: .26rem;
          margin-left: .02rem;
        }
      }
    }
  }
  .title{
    color: #1A1A1A;
    font-weight: 500;
    font-size: .32rem;
    padding: .26rem 0;
  }
  .details{
    font-size: .26rem;
    color: #4D4D4D;
    font-weight: 500;
    padding-bottom: .2rem;
    padding-top: .2rem;
    border-bottom: solid 1px #EEEEEE;
    .time{
      font-size: .24rem;
      color: #989898;
    }
  }
  .contant{
    text-align: justify;
    font-size: .26rem;
    color:#808080;
    line-height: .38rem;
  }
  .educationExperience{
    border-top: solid 1px #EEEEEE;
  }
  .workExperience{
    .position{
      font-size: .26rem;
      color: #4D4D4D;
      line-height: .38rem;
      padding: .12rem 0;
    }
  }
  .skillsfile{
    padding-bottom: .3rem;
    .avaterWrap{
      padding-top: .2rem;
      .avater{
        width: 1.3rem;
        height: 1.3rem;
        margin-right: .1rem;
        border-radius: 10px;
      }
    }
    
  }
  .searchWork{
    .workCommand{
      padding-bottom: .22rem;
    }
    .workCommand>span{
      font-size: .26rem;
      color: #808080;
      margin-right: .1rem;
    }
  }
  .chatButton{
    border-radius: .48rem;
    // margin-bottom: 1rem ;
    margin-top:.47rem;
    font-size: .32rem;
  }
}
@primary-color: #1DA57A;