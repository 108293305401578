.myTrain{
  padding-bottom: .5rem;
  .myTrainTop{
    padding: .3rem;
    width: 100%;
    height: 1.6rem;
    background-image: url(../../../image/myTrainImg/backgroundImg.png);
    background-size:cover;
    border-radius: 0 0 .3rem .3rem;
    .course{
      width: 3.15rem;
      height: .48rem;
      background-color:  rgba(255, 255, 255, 0.15);
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: .24rem;
      .courseTitle{
        margin-left: .06rem;
        display: inline-block;
        width: .7rem;
        height: .4rem;
        margin-top: .02rem;
        background: #FFFFFF;
        font-size: .24rem;
        color: #29E6A8;
        border-radius: .18rem;
        text-align: center;
        line-height: .42rem;
      }
      .courseGrade{
        font-size: .26rem;
        color: #FFFFFF;
        padding-left: .24rem;
        line-height: .4rem;
      }
    }
  }
  .swiper{
    padding: 0 .3rem;
    position: relative;
    top: -.6rem;
    .adm-swiper{
      border-radius:5px;
      .adm-swiper-track-inner{
        border-radius:5px;
        height: 3rem;
        .adm-swiper-slide{
          border-radius:5px;
          transform: translateZ(0) ;
          .wrapBox{
            border-radius:5px;
            img{
              border-radius:5px;
            }
          }
        }
        
      }
    }
    .content{
      height: 100%;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 48px;
      user-select: none;
    }
    .adm-page-indicator{
      //轮播图单个小红点
      --dot-color:rgba(255, 255, 255, .4);
      --active-dot-color:#ffffff;
      --dot-size:.1rem;
      --active-dot-size:.24rem;
      --dot-border-radius:.06rem;
      --dot-spacing:.1rem;
    }
  }
  .subject{
    margin: 0 .3rem;
    margin-top: -.1rem;
    padding: 0 .3rem;
    height: .96rem;
    background: #F0F6FF;
    box-shadow: 0px 2px 6px 0px rgba(126, 137, 164, 0.08);
    border-radius: 10px;
    border: 1px solid rgba(44, 104, 255, 0.35);
    .subjectItem{
      height: .36rem;
      .subjectTitle{
        text-align: justify;
        width: 5.4rem;
        font-size: .26rem;
        color: #808080;
        line-height: .36rem;
      }
      .subjectContent{
        margin-left: .1rem;
        font-size: .3rem;
        font-weight: 600;
      }
    }
    .subjectLast{
      padding-left: .4rem;
      border-left: 1px solid rgba(172, 197, 255, 1);
      .subjectContent{
        color: #2C68FF
      }
    }
  }
  .subjectList{
    margin:.3rem;
    .subjectListItem{
      position: relative;
      background: #ffffff;
      margin-bottom: .2rem;
      height: 1.6rem;
      padding: .26rem .13rem .12rem .3rem;
      border-radius: 10px;
      .listItemLf{
        .itemLfTitle{
          font-size: .34rem;
          color: #1A1A1A;
          font-weight: 600;
          line-height: .4rem;
        }
        .itemLfNum{
          font-size: .28rem;
          line-height: .32rem;
          color: #2C68FF;
          margin-top: .1rem;
        }
        .itemLfdesc{
          font-size: .28rem;
          line-height: .32rem;
          color: #A6A6A6;
          margin-top: .1rem;
        }
        .itemFraction{  
          font-size: .28rem;
          line-height: .32rem;      
          color: #34E4AA;
          margin-top: .1rem;
        }
      }
      .listItemRg{
        position: absolute;
        right: .08rem;
        bottom: .14rem;
        img{
          width: .64rem;
          height: .64rem;
        }
      }
    }
  }
}
@primary-color: #1DA57A;