.balloonLeftWrap{
  .balloonLeft {
    vertical-align: top;
    margin: .2rem 0;
    margin-left: .2rem;
    position: relative;
    display: inline-block;
    padding: .2rem;
    width: auto;
    min-width: 1.5rem;
    max-width: 4.6rem;
    min-height: .8rem;
    text-align: left;
    background: #F9F9F9;
    border-radius: 10px;
    color: #1A1A1A;
    line-height: .44rem;
    word-wrap:break-word;
    z-index: 0;
  }
  .balloonLeft:before {
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #F9F9F9 transparent transparent;
    content: "";
    position: absolute;
    top: .4rem; left: -8px;
    margin-top: -9px;
    display: block;
    width: 0px;
    height: 0px;
    z-index: 0;
  }
  .balloonLeft:after {
    border-style: solid;
    border-width: 11px 11px 11px 0;
    border-color: transparent #F9F9F9 transparent transparent;
    content: "";
    position: absolute;
    top: .4rem; 
    left: -8px;
    margin-top: -10px;
    display: block;
    width: 0px;
    height: 0px;
    z-index: -1;
  }
  .avatarleft{
    border-radius: 5px;
    width: .8rem;
    height: .8rem;
    margin-top: .2rem;
  }
}

@primary-color: #1DA57A;