.myResume{
  background: #ffffff;
  .myResumeHead{
    width: 100%;
    height: 1.9rem;
    background: #2C68FF;
    .resumePublish{
      padding: .4rem .3rem ;
      font-size: .28rem;
      color: #ffffff;
    }
  }
  .myResumeItem{
    position:relative ;
    top: -1rem;
    // .adm-list-inner{
    //   .publicFormItem:nth-child(1){
    //     height: 1.26rem;
    //   }
    // }
  }
  .headImg{
    padding: .4rem 0;
    border-radius:30px 30px 0px 0px;
    background: #ffffff;
    position: relative;
    // left: 3rem;
    top: -.6rem;
    z-index: 9000;
    .headTitle{
      padding-left: .3rem;
      font-size: .36rem;
      font-weight: 600;
      // float: left;
    }
    .imgUploadSign{
      padding: 0 .3rem;
      // display: inline-block;
      // position: relative;
      // width: 1.5rem;
      // height: 1.5rem;
      .UploadSign{
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 5px;
        // display: inline-block;
      }
      // .UploadSignInput{
      //   display: block;
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   z-index: 9;
      //   opacity: 0;
      // }
    }
  }
  .headTip{
    padding-left: .3rem;
    font-size: .24rem;
    line-height: .6rem;
    color: #34E4AA;
  }
}
@primary-color: #1DA57A;