.admissionTicket{
  height: 100%;
  .overlayContent{
    width: 3rem;
    height: .8rem;
    // margin-top: 20%;
    border-radius: .4rem;
      position: absolute;
      left: .6rem;
      z-index: 1000;
      opacity: 0;
      // background: green;
    .adm-image-uploader-upload-button-wrap{
      display: none;
    }
    .adm-image-uploader-cell, .adm-image ,.adm-image-uploader-cell-image{
      // width: 6.4rem;
      // height: auto;
      width: 3rem;
      height: .8rem;
      border-radius: .4rem;
    }
  }
  .overlaynone{
    width: 3rem;
    height: .8rem;
    // margin-top: 20%;
    border-radius: .4rem;
      position: absolute;
      left: 2.4rem;
      z-index: 1000;
      opacity: 0;
    .adm-image-uploader-upload-button-wrap{
      display: none;
    }
    .adm-image-uploader-cell, .adm-image ,.adm-image-uploader-cell-image{
      // width: 6.4rem;
      // height: auto;
      width: 3rem;
      height: .8rem;
      border-radius: .4rem;
    }
  }
  padding: .3rem;
  .ticketBox{
    font-size: .28rem;
    background: #ffffff;
    padding: .3rem;
    padding-bottom: 0;
    border-radius: .2rem;
    box-shadow: 0px 2px 6px 0px rgba(126, 137, 164, 0.19);
    .ticketInner{
      position: relative;
      display: block;
      padding-bottom: .2rem;
      .ticketTitle{
        position: absolute;
        top:0;
        display: inline-block;
        width: 1.5rem;
        height: .4rem;
        // line-height: .4rem;
        color: #808080;
        text-align-last:justify;
	      text-align:justify;
	      text-justify:distribute-all-lines; // 这行必加，兼容ie浏览器
        // float: left;
      }
      .ticketConent{
        display: inline-block;
        margin-left: 1.5rem;
        width: 3.6rem;
        color: #1A1A1A;
        line-height: .4rem;
        // float: right;
        .ticketConentinner{
          width: 3.6rem;
        }
      }
      .ticketConentTips{
        font-size: .24rem;
        color: #666666;
        line-height: .33rem;
      }
    }
  }
  .ticketBottom{
    margin-top: .6rem;
    font-size: .3rem;
    line-height: .42rem;
    .downloadTicket{
      // position: relative;
      margin-left: .3rem;
      display: inline-block;
      color: #2C68FF;
      width: 3rem;
      height: .8rem;
      line-height: .8rem;
      text-align: center;
      background: #E6EDFF;
      border-radius: .4rem;
      border: 1px solid #2C68FF;
    }
    .scoreQuery{
      position: relative;
      // left: -3rem;
      margin-left: .3rem;
      display: inline-block;
      width: 3rem;
      height: .8rem;
      background: #2C68FF;
      border-radius: .4rem;
      border: 1px solid #EEEEEE;
      color: #ffffff;
      line-height: .8rem;
      text-align: center;
    }
  }
  .adm-modal-wrap{
    // margin-top: -1rem;
    // z-index: 100000;
    width:5rem;
  }
  .adm-modal-main{
    text-align: center;
    background: linear-gradient(176deg, #E6EFFF 0%, #FFFFFF 100%);
  }
  .subject{
    margin-bottom: .3rem;
    .subjectTitle{
      text-align: justify;
      width: 5.4rem;
      font-size: .3rem;
      color: #666666;
      line-height: .42rem;
    }
    .subjectScore{
      font-size: .4rem;
      font-weight: 600;
      color: #4D4D4D;
      line-height: .45rem;
    }
    .subjectUnit{
      font-size: .26rem;
      color: #4D4D4D;
      line-height: .37rem;
    }
  }
}
// .ticketDialog{
//   background-color: palegoldenrod;
//   .overlayContent {
//     width: 100%;
//     height: 100%;
//   }
// }


@primary-color: #1DA57A;