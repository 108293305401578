.recruitMarket{
  .adm-list .adm-list-inner{
    padding: 0 0 !important;
    border-radius:0 0 !important;
  }
  .adm-list-item-content{
    padding-top:0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .eneryBox{
    height: 1.83rem;
    background: #FFFfff;
    position: relative;
    // border-bottom: 1px solid #EDEDED;
    // margin-top: .3rem;
    .eneryBoxLeft{
      // float: left;
      // margin-left: .2rem;
      .roll{
        width: .18rem;
        height: .18rem;
        background: #F93A42;
        border-radius: 50%;
        position: relative;
        top: -.7rem;
        left: .5rem;
      }
      .adm-image{
        width: 1.3rem;
        height: 1.3rem;
      }
    }
    .eneryBoxRight{
      // float: right;
      margin-left: .3rem;
      padding-right: .3rem;
      width: 5.2rem;
      height: 100%;
      padding-top:.26rem ;
      // padding-bottom:.2rem ;
      border-bottom: 1px solid #EDEDED;
      .lineOne{
        color: #2F2F2F;
        font-size: .3rem;
        padding-bottom: .1rem;
        .lineOneTime{
          margin-right: .3rem;
          font-size: .22rem;
          color: #B8BECC;
        }
      }
      .lineTwo{
        margin-top: .1rem;
        font-size: .28rem;
        color: #666;
        .lineTwoSpan{
          margin-right: .2rem;
        }
      }
      .lineThree{
        margin-top: .24rem;
        font-size: .24rem;
        color: #B8BECC;
        .newsTipUnRead{
          color: #2C68FF;
        }
        .newsText{
          line-height: .28rem;
          display: inline-block;
          max-width: 4rem;
          vertical-align: middle;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .adm-list .adm-list-default{
        border-top:none;
      }
    }
  }
  .adm-button{
    width: 1rem;
    font-size: .26rem;
  }
  .topNavigation{
    width: 100%;
    height: .9rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #2C68FF;
    .topNgtLf{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .NavigationSpan{
        padding: 0 .4rem;
        .textActive{
          font-size: .36rem;
          font-weight: 600;
          color: #F5F5F5;
        }
        .textDefault{
          font-size: .32rem;
          color: rgba(245, 245, 245, .8);
        }
        .bottomLineActive{
          width: .4rem;
          height: .04rem;
          margin: 0 auto;
          background: #F5F5F5;
          position: relative;
          top: .24rem;
        }
      }
      
    }
    .topNgtRg{
      font-size: .32rem;
      color: rgba(245, 245, 245, .8);
    }
  }

  .publicSearchBar{
    padding: .28rem .3rem;
  }
  .recruitMarketList{
    width: 100%;
    .itemBox{
      width: 100%;
      // height: 2.7rem;
      background: #FFFfff;
      margin-bottom: .2rem;
      padding-bottom: .2rem;
      .itemTopNull{
        height: .32rem;
      }
      .itemLineOne{
        height: .45rem;
        line-height: .45rem;
        margin: 0 .3rem;
        .OneTitle{
          font-size: .32rem;
          font-weight: 600;
          color: #1A1A1A;
        }
        .OneMoney{
          font-size: .3rem;
          color: #34E4AA;
        }
      }
      .itemLineTwo{
        font-size: .24rem;
        margin: 0 .3rem;
        margin-top: .2rem;
        .TwoSpan{
          display: inline-block;
          padding: .08rem .15rem;
          background:#F5F5F5 ;
          margin-right: .1rem;
          color: #666666;
        }
      }
      .itemLineThree{
        font-size: .24rem;
        margin: 0 .3rem;
        margin-top: .24rem;
        color: #808080;
        .ThreeSpan1{
          padding-right: .16rem;
          // border-right: solid 1px #E6E6E6;
        }
        .ThreeSpan2{
          padding-left: .16rem;
        }
      }
      .itemLinefour{
        margin: 0 .3rem;
        margin-top: .16rem;
        // margin-left: .3rem;
        text-align: right;
        font-size: .22rem;
        color: #B3B3B3;
      }
    }
    .eneryBox{
      height: 1.83rem;
      background: #FFFfff;
      position: relative;
      // border-bottom: 1px solid #EDEDED;
      // margin-top: .3rem;
      .eneryBoxLeft{
        // float: left;
        margin-left: .3rem;
        .roll{
          width: .18rem;
          height: .18rem;
          background: #F93A42;
          border-radius: 50%;
          position: relative;
          top: -.7rem;
          left: .5rem;
        }
      }
      .eneryBoxRight{
        // float: right;
        margin-right: .3rem;
        width: 5.2rem;
        border-bottom: 1px solid #EDEDED;
        .lineOne{
          color: #2F2F2F;
          font-size: .3rem;
          .lineOneTime{
            font-size: .22rem;
            color: #B8BECC;
          }
        }
        .lineTwo{
          margin-top: .1rem;
          font-size: .24rem;
          color: #ABAFB9;
          .lineTwoSpan{
            margin-right: .2rem;
          }
        }
        .lineThree{
          margin-top: .24rem;
          font-size: .24rem;
          color: #B8BECC;
          .newsTipUnRead{
            color: #2C68FF;
          }
          .newsText{
            display: inline-block;
            width: 4.8rem;
            vertical-align: middle;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
    .adm-button{
      width: 1rem;
      font-size: .26rem;
    }
  }
}
.adm-dialog-main{
  .recruitBody{
    padding: .4rem .12rem;
    font-size: .26rem !important;
  }
  .adm-dialog-footer{
    .adm-button{
      padding: .3rem 0 !important;
      font-size: .26rem !important;
    }
  }
}

@primary-color: #1DA57A;