html,
body {
  background: #ffffff;
}
.employChat{
  // height: 100%;
  position: relative;
  background: #ffffff;
  .chatTop{
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 100;
    .contactInfo{
      height: .72rem;
      font-size: .28rem;
      line-height: .72rem;
      padding: 0 .3rem;
      background: #E6EEFC;
      .telphone{
        color: #030303;
        line-height: .32rem;
      }
      .interestDefault{
        font-size: .24rem;
        color: #808080;
        line-height: .32rem;
      }
      .interest{
        font-size: .24rem;
        color: #F93A42;
        line-height: .32rem;
      }
    }
    .businessCard{
      height: 1.4rem;
      margin: .3rem;
      box-shadow: 0px 2px 16px 0px rgba(130, 136, 150, 0.23);
      border-radius: 10px;
      border: 1px solid #EEEEEE;
      .itemTopNull{
        height: .32rem;
      }
      .itemLineOne{
        height: .45rem;
        line-height: .45rem;
        margin: 0 .3rem;
        padding-top: .3rem;
        .OneTitle{
          font-size: .32rem;
          font-weight: 600;
          color: #1A1A1A;
        }
        .OneMoney{
          font-size: .3rem;
          color: #34E4AA;
        }
      }
      .itemLineTwo{
        font-size: .24rem;
        margin-top: .2rem;
        .itemLineTwoLf{
          margin: 0 .3rem;
          display: inline-block;
          .TwoSpan{
            display: inline-block;
            max-width: 2rem;//显示省略号
            overflow: hidden;
            margin-right: .1rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            // white-space:nowrap;/*强制文本在一行内显示*/
            // text-overflow:ellipsis; /*溢出省略号，支持ie、safari（webkit）*/
            // -o-text-overflow:ellipsis; /*溢出省略号，支持opera*/
            // overflow:hidden;/*溢出隐藏*/
            padding: .08rem .15rem;
            background:#F5F5F5 ;
            margin-right: .1rem;
            color: #666666;
          }
        }
        .itemLineTwoRg{
          margin: .04rem .3rem;
          position: absolute;
          right: .3rem;
          display: inline-block;
        }
      }
      .itemLineThree{
        font-size: .24rem;
        margin: 0 .3rem;
        margin-top: .24rem;
        padding-bottom: .3rem;
        color: #808080;
        .ThreeSpan1{
          padding-right: .16rem;
          border-right: solid 1px #E6E6E6;
        }
        .ThreeSpan2{
          padding-left: .16rem;
        }
      }
      .itemLineTwoRg{
        // display: inline-block;
        margin: .04rem  .3rem;
        // margin-top: .16rem;
        // margin-left: .3rem;
        // text-align: right;
        // vertical-align: top;
        font-size: .22rem;
        color: #B3B3B3;
      }
    }
    .tipNews{
      text-align: center;
      font-size: .22rem;
      color: #B3BEC9;
      line-height: 24px;
    }
  }
  .messageBoard{
    background: #ffffff;
    // height: calc(100%-4.02rem);//tipNews是。1+。3+。3=。7rem
    padding: 0 .3rem;
    padding-top:3.2rem;
    // margin-top:3rem;
    padding-bottom: .8rem;
    font-size: .28rem;
  }
  .chatBottom{
    padding: .18rem .3rem;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #F5F5F5;
    .adm-input-wrapper{
      .adm-input{
        width: 4.6rem;
        padding: 0 .12rem;
        background: #ffffff;
        font-size: .28rem;
        line-height: .56rem;
      }
    }
    .senoutImg{
      width: .44rem;
      height: .44rem;
      margin:0 .3rem ;
    }
    .refreshImg{
      width: .44rem;
      height: .44rem;
    }
  }
}
@primary-color: #1DA57A;