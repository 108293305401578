.competitionAward{
  .publicFormItem:last-child{
    --border-inner:none;
  }
  .infoTableItem{
    .adm-list-inner{
      .publicFormItem:nth-child(2){
        .adm-list-item-content{
          display: flex;
          flex-wrap: wrap;
          .adm-list-item-content-prefix{
            --prefix-width: 7.9em !important;
          }
        }
      }
    }
  }
}
@primary-color: #1DA57A;