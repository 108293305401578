.workDetail{
  background: #FFFFFF;
  padding: 0 .3rem;
  .moduleBox{
    padding-top: .3rem;
  }
  .moduleOne{
    padding-bottom: .2rem;
    border-bottom: solid 1px #E8E9EC;
    .oneRow1{
      .position{
        font-size: .32rem;
        color: #1A1A1A;
        line-height: 45px;
        font-weight: 600;
      }
      .salary{
        font-size: .3rem;
        color: #34E4AA;
        line-height: 34px;
      }
    }
    .oneRow2{
      .icon > img{
        width: .24rem;
        height: .24rem;
      }
      .iconEdu>img{
        width: .28rem;
        height: .28rem;
      }
      // white-space:nowrap;
      padding-top: .28rem;
      .jobAddress{
        display: inline-block;
        max-width: 2.4rem;
        white-space:nowrap;/*强制文本在一行内显示*/
        text-overflow:ellipsis; /*溢出省略号，支持ie、safari（webkit）*/
        -o-text-overflow:ellipsis; /*溢出省略号，支持opera*/
        overflow:hidden;/*溢出隐藏*/
        // -moz-binding:url('ellipsis.xml#ellipsis');/*溢出省略号，支持firefox*/
      }
      .iconText{
        padding-left: .04rem;
        font-size: .22rem;
        color: #808080;
        vertical-align: bottom;
      }
    }
  }
  .moduleTwo{
    .detailsTitle{
      font-size: .28rem;
      font-weight: 600;
      color: #1A1A1A;
      line-height: 40px;
    }
    .detailsText{
      font-size: .24rem;
      color: #666666;
      line-height: .4rem;
    }
  }
  .moduleThree{
    display: flex;
    border-top: solid 1px #E8E9EC;
    border-bottom: solid 1px #E8E9EC;
    margin-top: .3rem;
    padding: .3rem 0;
    .avator{
      display: inline-block;
      width: 1.3rem;
      height: 1.3rem;
      border-radius: 10px;
    }
    .addressDetails{
      padding-left: .2rem;
      .addressDetails1{
        font-size: .3rem;
        color: #1A1A1A;
        line-height: .42rem;
      }
      .addressDetails2{
        height: .4rem;
        font-size: .24rem;
        color: #1A1A1A;
        line-height: .4rem;
      }
      .addressDetails3{
        font-size: .24rem;
        color: #333333;
        line-height: .33rem;
      }
    }
  }
  .chatButton{
    border-radius: .48rem;
    // margin-bottom: 1rem ;
    margin-top:.47rem;
    font-size: .32rem;
  }
}
@primary-color: #1DA57A;