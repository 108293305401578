.problemAnswer{
  .subjectBriefwrap{
    height: 6rem;
    overflow-y: auto;
  }
  .topic{
    position: relative;
    margin: 0 .3rem;
    width: 6rem;
    height: 7.5rem;
    overflow-y:visible;
    background: #ffffff;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.07);
    border-radius: .2rem;
    border: 1px solid #EDEDED;
    // z-index: 9;
    .topicTitle{
      position: absolute;
      z-index: 100;
      margin-left: .2rem;
      .title{
        display: inline-block;
        width: 1.72rem;
        height: .7rem;
        font-size: .28rem;
        color: #ffffff;
        text-align: center;
        line-height: .7rem;
        background: url(../../image/orderTrain/dialogue.png);
        background-size: 100%;
        position: relative;
        top: -.25rem;
      }
      .titleValue{
        margin-left: .1rem;
        font-size: .28rem;
        color: #989898;
        line-height: .32rem;
        .titleCurrent{
          font-size: .32rem;
          color: #1A1A1A;
        }
      }
    }
    .topBackground{
      // z-index: 10000000;
      position: absolute;
      border-radius: .2rem .2rem 0 0;
      width: 5.9rem;
      height: .7rem;
      background: #ffffff;
    }
    .subjectTitle{
      text-align: justify;
      width: 5.4rem;
      color: #333333;
      line-height: .42rem;
      font-size: .3rem;
      margin-left: .33rem;
      margin-top: .86rem;
      margin-bottom: .2rem;
      word-break: break-all;
        img{
          width: 5rem;
        }
    }
    .subjectOption{
      // height: 4.6rem;
      // overflow-y: scroll;
      margin-left: .33rem;
      .adm-selector-item{
        padding-left: .47rem;
        width: 5.4rem;
        min-height: .85rem;
        line-height: .32rem;
        padding-top: .25rem;
        padding-bottom: .25rem;
        background: #F5F7FB;
        border-radius: .42rem;
        font-size: .28rem;
        color: #666666;
        margin-bottom: .25rem;
        text-align: left;
      }
      .adm-selector-item-active{
        background: #F0F4FF;
        border-radius: .43rem;
        color: #2C68FF;
        border: 2px solid #2C68FF;
      }
      .adm-selector-item-multiple-active{
        background: #F0F4FF;
        border-radius: .43rem;
        color: #2C68FF;
        border: 2px solid #2C68FF;
      }
      .adm-selector-item-disabled{
        opacity:.8;
      }
      .optionItem{
        margin-top: .25rem;
        padding-left: .47rem;
        width: 5.4rem;
        min-height: .85rem;
        line-height: .32rem;
        padding-top: .25rem;
        padding-bottom: .25rem;
        background: #F0F4FF;
        border-radius: .42rem;
        font-size: .28rem;
        color: #666666;
        margin-bottom: .25rem;
        text-align: left;
        word-break: break-all;
      }
      .trueAnswer{
        border: 2px solid #34E4AA;
        z-index: 9;
      }
      .falseAnswer{
        border: 2px solid #F93A42;
      }
    }
    .subjectjudge{
      margin-left: .33rem;
      .adm-selector-item{
        text-align: center;
        width: 5.4rem;
        height: .85rem;
        background: #F5F7FB;
        border-radius: .42rem;
        font-size: .28rem;
        color: #666666;
        line-height: .8rem;
        margin-bottom: .25rem;
      }
      .adm-selector-item-active{
        background: #F0F4FF;
        border-radius: .43rem;
        color: #2C68FF;
        border: 2px solid #2C68FF;
      }
    }
    .subjectBriefAnswer{
      padding: 0 .3rem;
      .briefPicture{
        .briefContent{
          font-size: .28rem;
          margin-left: .1rem;
          text-align: justify;
          padding-bottom: .3rem;
          word-break: break-all;
          img{
            width: 5rem;
          }
        }
        .briefPictureContent{
          margin-bottom: .3rem;
        }
      }
      .adm-text-area-wrapper{
        margin-bottom: .3rem;
        .adm-text-area{
          padding: .28rem;
          font-size: .28rem;
          background: #F5F7FB;
          border-radius: .12rem;
        }
      }
    }
  }
  .answer{
    position: fixed;
    margin: .3rem .3rem;
    width: 6rem;
    height: 2.4rem;
    overflow-y:auto;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    border: 1px solid #EDEDED;
    padding: .24rem .3rem;
    .tureAnswer{
      .tureAnswerTitle{
        font-size: .28rem;
        font-weight: 600;
        color: #333333;
        line-height: .4rem;
      }
      .tureAnswerContent{
        font-size: .32rem;
        color: #34E4AA;
        line-height: .5rem;
        padding-left: .2rem;
      }
    }
    .thisSkills{
      margin: .2rem 0;
      // width: 1.68rem;
      // height: .42rem;
      .thisSkillsTitle{
        width: .36rem;
        height: .36rem;
      }
      .thisSkillsContent{
        display: inline-block;
        font-size: .3rem;
        font-weight: 600;
        color: #333333;
        padding-left: .1rem;
        // line-height: .42rem;
        vertical-align:bottom;
      }
    }
    .answerAnalysis{
      font-size: .26rem;
      color: #666666;
      line-height: 50px;
      text-align: justify;
      word-break: break-all;
      img{
        width: 5rem;
      }
    }
  }
  .topicAnswerChoice{
    height: 6.5rem;
  }
  .topicAnswerjudge{
    height: 4.2rem;
  }
  .answerJudge{
    height: 4.6rem;
  }
  .answerBrief{
    height: 2.4rem;
  }
  .topicAnswerBrief{
    height: 6.4rem;
    .subjectBriefAnswerContent{
      text-align: justify;
      padding: .3rem;
      // width: 6.3rem;
      min-height: 2rem;
      margin-bottom: .3rem;
      background: #F5F7FB;
      border-radius: .12rem;
      font-size: .28rem;
      color: #1A1A1A;
      line-height: .4rem;
      word-break: break-all;
        img{
          width: 5rem;
        }
    }
  }
  .tureBriefAnswerContent{
    padding-top: .1rem;
    font-size: .26rem;
    color: #4D4D4D;
    line-height: 50px;
    text-align: justify;
    word-break: break-all;
        img{
          width: 5rem;
        }
  }
}
@primary-color: #1DA57A;