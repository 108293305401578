.testAppointList{
  margin: 0 .2rem;
  .testAppointListItem{
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(126, 137, 164, 0.08);
    border-radius: .1rem;
    margin-bottom: .2rem;
    padding: .22rem .3rem;
    .testTitle{
      font-size: .32rem;
      color: #1A1A1A;
      line-height: .45rem;
      margin-bottom: .2rem;
    }
    .testTime,.endTime{
      color: #808080;
      line-height: .37rem;
      font-size: .26rem;
      margin-bottom: .1rem;
    }
    .endTime{
      margin-bottom: .3rem;
    }
    .signUp{
      width: 1.6rem;
      height: .55rem;
      background: #2C68FF;
      border-radius: .28rem;
      color: #FFFFFF;
      line-height: .55rem;
      font-size: .26rem;
      text-align: center;
      margin-left: 4rem;
    }
    .end{
      width: 1.6rem;
      height: .55rem;
      background: rgba(128, 128, 128, 0.1);
      border-radius: .28rem;
      color: #808080;
      line-height: .55rem;
      font-size: .26rem;
      text-align: center;
      margin-left: 4rem;
    }
  }
}
.appointModal{
  padding-top: .4rem !important;
  .subjectTips{
    font-size: .28rem;
    text-align: center;
    line-height: .4rem;
  }
}

@primary-color: #1DA57A;