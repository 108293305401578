.twoButton{
  font-size: .28rem;
  .awardIsEntryButton{
    div:first-child{
      margin-left: .28rem;
    }
    div{
      margin-left: .16rem;
    }
    .checkStyle{
      display: inline-block;
      width: 1.2rem;
      height: .5rem;
      text-align: center;
      line-height: .5rem;
      border-radius: .3rem;
      font-size: .28rem;
      background:#2C68FF;
      color: #ffffff;
    }
    .notCheckStyle{
      display: inline-block;
      width: 1.2rem;
      height: .5rem;
      text-align: center;
      line-height: .5rem;
      border-radius: .3rem;
      font-size: .28rem;
      background:#F5F5F5;
      color: "#4D4D4D"
    }
  }
}
.editAddwrapBox{
  // margin-right: 1rem;
  display: inline-block;
  padding-top: .08rem;
  color: #2C68FF;
  .imgAdd{
    margin-left: 1rem;
    margin-right: .24rem;
    width: .28rem;
    height: .28rem;
  }
}

.myInfo{
  width: 100%;
  overflow-x:hidden;
  .adm-list{
    // --prefix-width: 6.4em !important;
    --border-top: "none !important";
    --border-bottom: "none !important";
    --align-items:stretch !important;
    font-size: .28rem;
    background: #FFFFFF;
    box-shadow: 0 -.06rem .12rem 0 rgba(126, 137, 164, 0.06);
    border-radius: .3rem .3rem 0 0;
    .adm-list-inner{
      padding: .2rem 0;
      .adm-list-item{
        padding-right: .3rem;
        .adm-list-item-content{
          padding: 12px 0 12px 0 !important;
        }
      }
      .adm-list-item-content-main{
        .adm-text-area{
          font-size: .28rem;
          // min-height: .6rem !important;
          // color:#686B73 !important;
        }
        .twoButton{
          margin-left: -.4rem;
        }
        .pickerValues{
          display: inline-block;
          padding: .1rem 0;
          color:#686B73 !important;
        }
        .pickerTips{
          display: inline-block;
          padding: .1rem 0;
          color: #cccccc;
        }
        .adm-list-item-title{
          font-size: .28rem;
        }
        .adm-input-wrapper{
          input:-internal-autofill-selected{
            background-color: -internal-light-dark(#FFFFFF, rgba(70, 90, 126, 0.4)) !important;
          }
          .adm-input{
            font-size: .28rem;
          }
        }
        .adm-list-item-description{
          .adm-form-item-footer{
            font-size: .28rem;
          }
        }
      }
      .adm-list-item-description{
        .adm-form-item-footer{
          font-size: .28rem;
        }
      }
      .adm-list-item-description{
        .adm-form-item-footer{
          font-size: .28rem;
        }
      }
    }
  }
  .adm-form-footer{
    .adm-button{
      font-size: .32rem;
    }
  }
  .adm-form .adm-list{
    --padding-left:.3rem;
    --border-top: "none !important";
    --border-bottom: "none !important";
  }
  .buttonForm{
    .adm-list-item{
      .twoButton{
        padding-right:1.3rem ;
      }
    }
  }
  .headImg{
    position: relative;
    left: 2.5rem;
    top: .6rem;
    z-index: 9;
    .imgUploadSign{
      // display: inline-block;
      position: relative;
      // top: -.3rem;
      // left:-.4rem;
      width: 1.5rem;
      height: 1.5rem;
      .UploadSign{
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        // display: inline-block;
      }
      .UploadSignInput{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        opacity: 0;
      }
    }
    .imgUploadText{
      width: 1.5rem;
      padding-top: .16rem;
      font-size: .26rem;
      text-align: center;
      color: #B3B5B9;
    }
  }
  .myInfoItem{
    width: 100%;
    overflow-x:hidden;
    position:relative ;
    top: -.6rem;
    font-size: .3rem;
    #contact_address{
      color:#686B73 ;
    }
    .adm-input-wrapper{
      --disabled-color:#686B73 !important;
      .adm-input{
        color:#686B73 !important;
      }
    }
    .baseDivBoxfirst{
        height: 1.3rem;
      }
    .submitButton{
      border-radius: .48rem;
      // margin-bottom: 1rem ;
    }
    .distance{
      padding: .2rem .3rem;
    }
    // .publicFormItem{
      padding-top: .1rem;
      padding-bottom: .1rem;
      .echoData{
        color: #999999;
      }
      .baseProStar{
        margin-left: -.08rem;
        margin-right: .08rem;
      }
        // margin-top: 0rem;
        .healthFileBox{
          margin: .3rem;
        }
        .healthFileBox > div{
          padding-bottom: .24rem;
          font-size: .28rem;
          color: #686B73;
        }
        .healthFileBoxTitle{
          font-size: .28rem;
          color: #363A44;
          font-weight: 500;
        }
        .healthFileBody{
          padding-right: .5rem;
        }
    }
    .editAddwrapBox{
      // margin-right: 1rem;
      display: inline-block;
      padding-top: .08rem;
      color: #2C68FF;
      .imgAdd{
        // margin-left: 1rem;
        margin-right: .24rem;
        width: .28rem;
        height: .28rem;
      }
    }
    .publicFormItem:nth-last-child(2){
      // margin-top: -.8rem;
    }
  
  
  // }
  .adm-dialog-wrap{
    border-radius:0;
    box-shadow: none;
    width:80vw;
    .DialogButtom:first-child{
      margin-right: .8rem;
    }
    .DialogButtom{
      display: inline-block;
      width: 40%;
    }
    .adm-list{
      .adm-list-inner{
        padding: 0 0;}
    }
    .editHealthRecords{
      .publicFormItem{
        padding-top: .1rem;
        padding-bottom: 0 !important;
      }
      .adm-list{
        --prefix-width: 6em !important;
        --align-items:stretch !important;
        --border-top: "none !important";
        --border-bottom: "none !important";
        font-size: .28rem;
        background: #FFFFFF;
        // box-shadow: 0 -.06rem .12rem 0 rgba(126, 137, 164, 0.06);
        // border-radius: .3rem .3rem 0 0;
        .adm-list-inner{
          // padding: .2rem .3rem;
          .infoTableItemTitle{
            font-size: .32rem;
            font-weight: 500;
            color: #363A44;
            line-height: .62rem;
            .leftSide{
              display: inline-block;
              width: .06rem;
              height: .26rem;
              background: #2c68ff;
              // border-radius: 0px .04rem .04rem 0px;
              margin-right: .12rem;
            }
          }
          // .adm-plain-anchor{
          //   color: #B3B3B3; 
          // }
          .adm-list-item-content-main{
            .adm-text-area{
              font-size: .28rem;
              // min-height: .6rem !important;
              // width: 6.42rem;
              // margin-top: .24rem;
              // font-size: .24rem;
              // background: #F5F7FB;
              // height: 1.72rem;
              // padding: .24rem .24rem;
            }
            .twoButton{
              margin-left: -.4rem;
            }
            .pickerValues{
              display: inline-block;
              padding: .1rem 0;
              color:#686B73 !important;
            }
            .pickerTips{
              display: inline-block;
              padding: .1rem 0;
              color: #cccccc;
            }
            .uploadTop{
              width: 140%;
              margin-left: -.24rem;
              .uploadTitle{
                color: #666666;
                padding-left: .1rem;
              }
              .uploadTips{
                padding: .04rem .06rem;
                font-size: .24rem;
                color: #2C68FF;
              }
            }
            .uploadWrap{
              padding: .3rem 0;
              display: flex;
              flex-wrap: wrap;
              .imgClear{
                width: .30rem;
                height: .30rem;
                position: absolute;
                right: .05rem;
                top: -.05rem;
              }
              .adm-image-uploader-space{
                --gap: 4px;
              }
              
            }
            .adm-list-item-title{
              font-size: .28rem;
            }
            .adm-input-wrapper{
              color:#686B73 !important;
              input:-internal-autofill-selected{
                background-color: -internal-light-dark(#FFFFFF, rgba(70, 90, 126, 0.4)) !important;
              }
              .adm-input{
                font-size: .28rem;
              }
            }
            .adm-list-item-description{
              .adm-form-item-footer{
                font-size: .24rem;
              }
            }
          }
        }
      }
    }
    .editGoWorkExperience{
      // .adm-text-area{
      //   min-height: 2rem;
      // }
      .job_content{
        .adm-list-item-content{
          flex-wrap: wrap;
          .adm-list-item-content-main{
            margin-top: .2rem;
          }
        }
      }
      #job_content{
        padding: .08rem .12rem;
        background: #F6F7F8;
      }
      .workTime{
        display: flex;
      justify-content: space-between;
      align-items: center;
        .adm-list-item-content{
          display: flex;
          flex-wrap: wrap;
        }
        .adm-input-wrapper{
          width: 1rem;
          background-color: greenyellow;
          display: flex;
          flex-wrap: wrap;
        }
      }
      .work_start_time ,.work_end_time{
        .adm-list-item-content-prefix{
          --prefix-width: 7em !important;
        }
      }
      // .editHealthRecords .adm-list{
      //   .adm-list-item-content-prefix{
          
      //   }
      // }
      // .chronic{
      //   .adm-list-item-content-prefix{
         
      //   }
      // }
    }
    .adm-auto-center{
      .adm-auto-center-content{
        font-size: .3rem;
      }
    }
  }
}

@primary-color: #1DA57A;