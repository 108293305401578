.myResumeItem{
  // font-size: .3rem;
  // .submitButton{
  //   border-radius: .36rem;
  //   margin-bottom: 1rem ;
  // }
  .publicFormItem{
    padding-top: .1rem;
    padding-bottom: .1rem;
    // .baseProStar{
    //   margin-left: -.08rem;
    //   margin-right: .08rem;
    // }
    .searchWorkiInfo{
      padding: .1rem 0;
      color: #1A1A1A;
      font-weight: 400;
      .searchCity{
        margin-left: -.1rem;
      }
    }
    .nullTip{
      width: 100%;
      height: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
      color: #cccccc;
    }
    .distance{
      padding: 0 .3rem;
      .healthFileBox > div{
        padding-bottom: .24rem;
        font-size: .28rem;
        color: #686B73;
      }
      .healthFileBoxTitle{
        font-size: .28rem;
        color: #363A44;
        font-weight: 500;
      }
      .healthFileBody{
        padding-right: .5rem;
      }
      .adm-image{
        border-radius: 10px;
      }
    }
  }
  .editAddwrapBox{
    // margin-right: 1rem;
    display: inline-block;
    padding: .08rem 0;
    color: #2C68FF;
    .imgAdd{
      // margin-left: 1.2rem;
      // margin-right: .24rem;
      width: .28rem;
      height: .28rem;
    }
  }

  .adm-list{
    // --prefix-width: 6.4em !important;
    --align-items:stretch !important;
    font-size: .28rem;
    background: #FFFFFF;
    box-shadow: 0 -.06rem .12rem 0 rgba(126, 137, 164, 0.06);
    border-radius: .3rem .3rem 0 0;
    .adm-list-inner{
      padding: .2rem 0;
      .adm-list-item{
        padding-right: .3rem;
        .adm-list-item-content{
          padding: 12px 0 12px 0 !important;
        }
      }
      .adm-list-item-content-main{
        .adm-text-area{
          font-size: .28rem;
          min-height: .6rem !important;
        }
        // .twoButton{
        //   margin-left: -.28rem;
        // }
        .adm-list-item-title{
          font-size: .28rem;
        }
        .adm-input-wrapper{
          input:-internal-autofill-selected{
            background-color: -internal-light-dark(#FFFFFF, rgba(70, 90, 126, 0.4)) !important;
          }
          .adm-input{
            font-size: .28rem;
          }
        }
        // .adm-list-item-description{
        //   .adm-form-item-footer{
        //     font-size: .32rem;
        //   }
        // }
      }
    }
  }
  .adm-form-footer{
    .adm-button{
      font-size: .32rem;
    }
  }
}
@primary-color: #1DA57A;