.infoTableItem{
  .buttonForm{
    position: relative;
  }
  .submitButton{
    border-radius: .48rem;
    margin-bottom: 1rem ;
  }
  .publicFormItem{
    padding-top: .1rem;
    padding-bottom: .1rem;
  }
  .adm-form .adm-list{
    --padding-left:none !important;
  }
  .adm-list{
    --prefix-width: 7em !important;
    --align-items:stretch !important;
    font-size: .28rem;
    background: #FFFFFF;
    box-shadow: 0 -.06rem .12rem 0 rgba(126, 137, 164, 0.06);
    border-radius: .3rem .3rem 0 0;
    .adm-list-inner{
      padding: .2rem 0;
      .publicFormItem{
        padding:  0 .3rem;
        // .adm-list-item{
        //   // padding-right: .2rem;
        // }
        .adm-list-item-content{
          padding: 12px 0 12px 0 !important;
          margin-left: .16rem;
        }
      }
      .infoTableItemTitle{
        // padding-left: .1rem;
        font-size: .32rem;
        font-weight: 500;
        color: #363A44;
        line-height: .62rem;
        .leftSide{
          display: inline-block;
          width: .06rem;
          height: .26rem;
          background: #2c68ff;
          border-radius: 0px .04rem .04rem 0px;
          margin-right: .12rem;
        }
      }
      // .adm-plain-anchor{
      //   color: #B3B3B3; 
      // }
      .adm-list-item-content-main{
        .adm-text-area{
          width: 6.42rem;
          margin-top: .24rem;
          font-size: .24rem;
          background: #F5F7FB;
          height: 1.72rem;
          padding: .24rem .24rem;
        }
        .twoButton{
          margin-left: -.4rem;
        }
        .pickerValues{
          display: inline-block;
          padding: .1rem 0;
        }
        .pickerTips{
          display: inline-block;
          padding: .1rem 0;
          color: #cccccc;
        }
        .uploadTop{
          margin-left: -.24rem;
          .uploadStar{
            position: relative;
            left: .07rem;
          }
          .uploadTitle{
            color: #666666;
            padding-left: .1rem;
          }
          .uploadTips{
            padding: .04rem .12rem;
            font-size: .24rem;
            color: #2C68FF;
          }
        }
        .uploadWrap{
          padding: .2rem 0;
          display: flex;
          flex-wrap: wrap;
          .imgClear{
            width: .30rem;
            height: .30rem;
            position: absolute;
            right: .05rem;
            top: -.05rem;
          }
          .adm-image-uploader-space{
            --gap: 4px;
          }
          
        }
        .adm-list-item-title{
          font-size: .28rem;
        }
        .adm-input-wrapper{
          input:-internal-autofill-selected{
            background-color: -internal-light-dark(#FFFFFF, rgba(70, 90, 126, 0.4)) !important;
          }
          .adm-input{
            font-size: .28rem;
          }
        }
        .adm-list-item-description{
          .adm-form-item-footer{
            font-size: .24rem;
          }
        }
      }
      .adm-list-item-description{
        .adm-form-item-footer{
          font-size: .24rem;
        }
      }
    }
  }
  .adm-form-footer{
    .adm-button{
      font-size: .32rem;
    }
  }
  .adm-dialog-wrap{
    .adm-auto-center-content{
      font-size: .28rem;
    }
  }
}
.adm-popup{
  .adm-popup-body{
    border-radius: .1rem .1rem 0 0;
    min-height: 4rem;
    //   .adm-picker{
    //   height: 5rem;
    //   .adm-picker-header{
    //     padding-top: .1rem;
    //     .adm-picker-header-button{
    //       font-size: .28rem;
    //     }
    //     .adm-picker-header-title{
    //       font-size: .28rem;
    //     }
    //   }
    //   // 开始S
    //   .adm-picker-body{
    //     // height: 4rem;
    //     .adm-picker-view{
    //       // height: 4rem;
    //       .adm-picker-view-column{
    //         .adm-picker-view-column-wheel{
    //           min-height: 8rem;
    //           .adm-picker-view-column-item{
    //             // margin-top: .5rem;
    //             height: .5rem;
    //             font-size: .28rem;
    //             // padding: .25rem;
    //             // .adm-picker-view-column-item-label{
    //             //   // margin-top: 3rem;
    //             //   // width: .4rem;
    //             //   // height: .6rem;
    //             // }
    //           }
    //         }
    //       }
    //       .adm-picker-view-mask{
    //         .adm-picker-view-mask-middle{
    //           height: .5rem;
    //         }
    //       }
    //     }
    //   }
      
    // }
  }
}

@primary-color: #1DA57A;