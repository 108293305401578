.assistant{
  padding-top: .45rem;
  .nullStyle{
    height: ".2rem";
    background: "#FFFFFF";
  }
    margin-top: .45rem;
    .adm-list-card{
      padding: .45rem .45rem;
      .adm-list-item-content{
        margin-left: .3rem;
        font-size: .34rem;
        // .adm-list-item-content-main{
        //   margin-left: .22rem;
        // }
      }
    }
}

@primary-color: #1DA57A;