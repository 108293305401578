.courseList{
  margin: 0 .2rem;
  .courseListItem{
    padding: .2rem;
    margin-bottom: .2rem;
    background: #ffffff;
    .itemLeft{
      position: relative;
      .itemPicture{
        width: 2.6rem;
        height: 2rem;
      }
      .pictureTime{
        width: 1.4rem;
        height: .55rem;
        background: #000000;
        border-radius: .3rem;
        opacity: 0.5;
        padding: 0 .15rem;
        position: absolute;
        top: .72rem;
        left: .7rem;
        .itemTimeImg{
          width: .29rem;
          height: .29rem;
        }
        .itemTime{
          color: #ffffff;
          font-size: .24rem;
        }
      }
      
    }
    .itemRight{
      width: 3.6rem;
      height: 2rem;
      margin-left: .2rem;
      .itemContent{
        font-size: .32rem;
        color: #1A1A1A;
        line-height: .45rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .itemTips{
        margin: .2rem 0 .22rem 0;
        .itemTipsSpan{
          color: #2C68FF;
          line-height: .24rem;
          padding: .08rem .12rem;
          background: #ECF1FF;
          font-size: .22rem;
          border-radius: 6px;
          margin-right: .1rem;
        }
      }
      .read{
        font-size: .22rem;
        line-height: .3rem;
        text-align: right;
      }
      .isRead{
        color: #808080;
      }
      .unRead{
        color: #34E4AA;
      }
    }
  }
  .loadingPlaceholder {
    padding-top: 30vh;
    text-align: center;
    color: var(--adm-color-weak);
    font-size: .32rem;
    .loadingWrapper {
      font-size: .3rem;
      margin-bottom: 24px;
    }
  }
}
@primary-color: #1DA57A;