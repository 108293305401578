#root{
  width: 100%;
  height: 100%;
}
.App{
  width: 100%;
  height: 100%;
}
.examination{
  position: relative;
  padding-top: 1.18rem;
  width: 100%;
  height: 100%;
  .background{
    width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
  }
  .orderTrainBottom{
    padding: 0 .3rem;
    width: 100%;
    height: 1rem;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    .collection{
      height: .4rem;
      font-size: .38rem;
      color: #B3B3B3;
      .collectionTitle{
        font-size: .26rem;
        line-height: .4rem;
        margin-left: .1rem;
        color: #333333;
      }
    }
    .itemSubmit{
      display: inline-block;
      font-size: .3rem;
      color: #ffffff;
      width: 2rem;
      height: .6rem;
      background: #2C68FF;
      line-height: .6rem;
      text-align: center;
      box-shadow: 0px 7px 12px -2px rgba(42, 30, 0, 0.02);
      border-radius: .5rem;
    }
    .lastQuestion{
      color: #2C68FF;
      background: #ffffff;
      border: 1px solid #2C68FF;
    }
  }
  .problemExam{
    position: fixed;
    .topic{
      position: relative;
      margin: 0 .3rem;
      width: 6rem;
      height: 9rem;
      overflow-y:auto;
      background: #ffffff;
      box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.07);
      border-radius: .2rem;
      border: 1px solid #EDEDED;
      // z-index: 9;
      .topicTitle{
        position: fixed;
        z-index: 100;
        background: #ffffff;
        margin-left: .2rem;
        .title{
          display: inline-block;
          width: 1.72rem;
          height: .7rem;
          font-size: .28rem;
          color: #ffffff;
          text-align: center;
          line-height: .7rem;
          background: url(../../../image/orderTrain/dialogue.png);
          background-size: 100%;
          position: relative;
          top: -.25rem;
        }
        .titleValue{
          margin-left: .1rem;
          font-size: .28rem;
          color: #989898;
          line-height: .32rem;
          .titleCurrent{
            font-size: .32rem;
            color: #1A1A1A;
          }
        }
      }
      .topBackground{
        position: fixed;
        border-radius: .2rem .2rem 0 0;
        width: 5.9rem;
        height: .7rem;
        background: #ffffff;
      }
      .subjectTitle{
        text-align: justify;
        width: 5.4rem;
        color: #333333;
        line-height: .42rem;
        font-size: .3rem;
        margin-left: .33rem;
        margin-top: .86rem;
        margin-bottom: .45rem;
        word-break: break-all;
        img{
          width: 5rem;
        }
      }
      .subjectOption{
        margin-left: .33rem;
        .adm-selector-item{
          padding-left: .47rem;
          width: 5.4rem;
          min-height: .85rem;
          line-height: .32rem;
          padding-top: .25rem;
          padding-bottom: .25rem;
          background: #F5F7FB;
          border-radius: .42rem;
          font-size: .28rem;
          color: #666666;
          margin-bottom: .25rem;
          text-align: left;
        }
        .adm-selector-item-active{
          background: #F0F4FF;
          border-radius: .43rem;
          color: #2C68FF;
          border: 2px solid #2C68FF;
        }
        .adm-selector-item-multiple-active{
          background: #F0F4FF;
          border-radius: .43rem;
          color: #2C68FF;
          border: 2px solid #2C68FF;
        }
        .adm-selector-item-disabled{
          opacity:.8;
        }
        .optionItem{
          padding-left: .47rem;
          width: 5.4rem;
          height: .85rem;
          background: #F0F4FF;
          border-radius: .42rem;
          font-size: .28rem;
          color: #666666;
          line-height: .85rem;
          margin-bottom: .25rem;
          text-align: left;
          word-break: break-all;
        }
        .trueAnswer{
          border: 2px solid #34E4AA;
          z-index: 9;
        }
        .falseAnswer{
          border: 2px solid #F93A42;
        }
      }
      .subjectjudge{
        margin-left: .33rem;
        .adm-selector-item{
          text-align: center;
          width: 5.4rem;
          min-height: .85rem;
          line-height: .32rem;
          padding-top: .25rem;
          padding-bottom: .25rem;
          background: #F5F7FB;
          border-radius: .42rem;
          font-size: .28rem;
          color: #666666;
          margin-bottom: .25rem;
        }
        .adm-selector-item-active{
          background: #F0F4FF;
          border-radius: .43rem;
          color: #2C68FF;
          border: 2px solid #2C68FF;
        }
      }
      .subjectBriefAnswer{
        padding: 0 .3rem;
        .briefPicture{
          .briefContent{
            font-size: .28rem;
            margin-left: .1rem;
            text-align: justify;
            padding-bottom: .3rem;
            word-break: break-all;
            img{
              width: 5rem;
            }
          }
          .briefPictureContent{
            margin-bottom: .3rem;
          }
        }
        .adm-text-area-wrapper{
          margin-bottom: .3rem;
          .adm-text-area{
            padding: .28rem;
            font-size: .28rem;
            background: #F5F7FB;
            border-radius: .12rem;
          }
        }
      }
    }
    // 案例分析和简答
    .areaposition{
      margin: 0 .3rem;
      .topicTitlearea{
        position: absolute;
        z-index: 100;
        width:5.6rem;
        border-radius: .2rem .2rem 0 0;
        background: #ffffff;
        margin-left: .2rem;
        .title{
          display: inline-block;
          width: 1.72rem;
          height: .7rem;
          font-size: .28rem;
          color: #ffffff;
          text-align: center;
          line-height: .7rem;
          background: url(../../../image/orderTrain/dialogue.png);
          background-size: 100%;
          position: relative;
          top: -.25rem;
        }
        .titleValue{
          margin-left: .1rem;
          font-size: .28rem;
          color: #989898;
          line-height: .32rem;
          .titleCurrent{
            font-size: .32rem;
            color: #1A1A1A;
          }
        }
      }
      .topBackground{
        position: fixed;
        border-radius: .2rem .2rem 0 0;
        width: 5.9rem;
        height: .7rem;
        background: #ffffff;
      }
    }
    .lastTopic{
      height: 9rem;
    }
  }
}
@primary-color: #1DA57A;