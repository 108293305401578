.publicApplySuccess{
  padding-top: 1.05rem;
  .tipsPicture{
    width: 2.52rem;
    margin: 0 auto;
    display: block;
    // margin-top: 1.05rem;
    margin-bottom: .46rem;
  }
  .tipsTitle{
    font-size: .32rem;
    // font-weight: 400;
    color: #1A1A1A;
    line-height: .45rem;
    text-align: center;
  }
  .tipsBackDetails{
    margin: .45rem .5rem auto .5rem;
    padding: 0 .35rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(126, 137, 164, 0.08);
    border-radius: 10px;
    font-size: .28rem;
    .details{
      padding-top: .3rem;
      border-bottom: 1px solid #EEEEEE;
      .titleLeft{
        // font-weight: 400;
        color: #4D4D4D;
        line-height: 40px;
      }
      .titleReight{
        // font-weight: 400;
        color: #2C68FF;
        line-height: .4rem;
      }
      .detailsContent{
        padding: .17rem 0 .3rem 0;
        // font-weight: 400;
        color: #808080;
        line-height: .4rem;
        text-align: left;
        word-break:break-all; 
        word-wrap:break-word;
      }
    }
  }

}
@primary-color: #1DA57A;