.recordCourse{
  .course{
    width: 100%;
    background: #2C68FF;
    padding-top: .24rem;
    .courseType{
      line-height: .45rem;
      font-size: .32rem;
      .activeStyle{
        font-weight: 600;
        color: #F5F5F5;
      }
      .activeBottomStyle{
        margin-top: .15rem;
        margin-bottom: .02rem;
        width: .4rem;
        height: .04rem;
        margin-left: .3rem;
        background: #F5F5F5;
      }
      .defaultStyle{
        color: #F5F5F5;
      }
      .defaultBottomStyle{
        margin-top: .15rem;
        margin-bottom: .02rem;
        width: .4rem;
        height: .04rem;
        margin-left: .3rem;
        background: #2C68FF;
      }
    }
  }
  .publicSearchBar{
    margin: .28rem 0;
  }
  .adm-mask-content{
    margin-top: 4rem;
  }
}
@primary-color: #1DA57A;