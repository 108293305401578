.recruit{
  .styleRec{
    width: 100%;
    height: 2.46rem;
    background: linear-gradient(180deg, #2c68ff, #dee9ff 62%, #f7fbff);
  }
  .adm-list-card{
    position: relative;
    top: -1.6rem;
  }
  .adm-list-item-content{
    width: 100% ;
    padding-left: 12px;
  }
  .picture{
    width: .7rem;
    height: .7rem
  }
  .listItem{
    // margin-top: ".2rem";
    // position: relative;
    width: 100%;
    height: 1.3rem;
    font-size: .34rem;
    display: flex;
    align-items: center;
    justify-content: center
  }
  .styleDiv{
    height: .3rem;
    background: rgba(247, 251, 255, 1);
  }
  .publicList{
    .adm-list-card{
      background: none;
      padding: .4rem .5rem;
      .adm-list-item-content{
        margin-left: .3rem;
        font-size: .34rem;
        .adm-list-item-content-main{
          margin-left: .3rem;
          padding-top: .34rem !important;
        }
      }
    }
  }
  .publicListMask{
    .tips{
      font-size: .28rem;
    text-align: center;
    line-height: .4rem;
    }
  }
}
@primary-color: #1DA57A;