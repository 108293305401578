.publicTips{
  display: flex;
  font-size: .32rem;
  font-weight: 600;
  flex-direction:column ;
  justify-content:  center ;
  align-items: center;
  align-content: center ;
  margin-top: 50%;
  .tipsInfo{
    margin-top: .4rem;
  }
}
@primary-color: #1DA57A;