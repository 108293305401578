#root{
  width: 100%;
  height: 100%;
}
.App{
  width: 100%;
  height: 100%;
}
.testDetails{
  position: relative;
  padding-top: 1.18rem;
  width: 100%;
  height: 100%;
  .background{
    width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
  }
  .orderTrainBottom{
    padding: 0 .3rem;
    width: 100%;
    height: 1rem;
    background: #ffffff;
    position: absolute;
    bottom: 0;
    .collection{
      height: .4rem;
      font-size: .38rem;
      color: #B3B3B3;
      .collectionTitle{
        font-size: .26rem;
        line-height: .4rem;
        margin-left: .1rem;
        color: #333333;
      }
    }
    .subjectTrue{
      font-size: .38rem;
      color: #34E4AA;
      .subjectTrueTitle{
        font-size: .3rem;
        margin-left: .1rem;
      }
    }
    .subjectFalse{
      font-size: .38rem;
      color: #F93A42;
      .subjectFalseTitle{
        font-size: .3rem;
        margin-left: .1rem;
      }
    }
    .submit{
      display: inline-block;
      font-size: .3rem;
      color: #ffffff;
      width: 3rem;
      height: .8rem;
      background: #2C68FF;
      line-height: .8rem;
      text-align: center;
      box-shadow: 0px 7px 12px -2px rgba(42, 30, 0, 0.02);
      border-radius: .5rem;
    }
  }
}
.collectDetailsBody{
  padding-top: .4rem !important;
  .subjectTips{
    font-size: .28rem;
    text-align: center;
    line-height: .4rem;
  }
}
@primary-color: #1DA57A;