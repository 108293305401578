.demandCommunication{
  .styleDiv{
    width: 100%;
    height: .2rem;
    background: #FFFfff;
  }
  .adm-list-item-content{
    padding-top:0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .eneryBox{
    height: 1.83rem;
    background: #FFFfff;
    position: relative;
    // border-bottom: 1px solid #EDEDED;
    // margin-top: .3rem;
    .eneryBoxLeft{
      // float: left;
      // margin-left: .3rem;
      // padding-bottom:.1rem ;
      .roll{
        width: .18rem;
        height: .18rem;
        background: #F93A42;
        border-radius: 50%;
        position: relative;
        top: -.7rem;
        left: .5rem;
      }
      .adm-image{
        width: 1.2rem;
        height: 1.2rem;
      }
    }
    .eneryBoxRight{
      // float: right;
      margin-left: .3rem;
      padding-right: .3rem;
      width: 5.2rem;
      height: 100%;
      padding-top:.26rem ;
      // padding-bottom:.1rem ;
      border-bottom: 1px solid #EDEDED;
      .lineOne{
        color: #2F2F2F;
        font-size: .3rem;
        padding-bottom: .1rem;
        .lineOneTime{
          padding-right: .2rem;
          font-size: .22rem;
          color: #B8BECC;
        }
      }
      .lineTwo{
        line-height: .28rem;
        margin-top: .1rem;
        font-size: .28rem;
        color: #666;
        .lineTwoSpan{
          display: inline-block;
          margin-right: .2rem;
          max-width: 2.5rem;//显示省略号
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .lineThree{
        margin-top: .18rem;
        font-size: .24rem;
        color: #B8BECC;
        .newsTipUnRead{
          color: #2C68FF;
        }
        .newsText{
          line-height: .28rem;
          display: inline-block;
          max-width: 4rem;
          vertical-align: middle;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
  .adm-button{
    width: 1rem;
    font-size: .26rem;
  }
  .adm-list .adm-list-default{
    border-top:none;
  }
}
.adm-empty-description{
  font-size: .4rem;
}
.adm-dialog-main{
  .recruitBody{
    padding: .4rem .12rem;
    font-size: .26rem !important;
  }
  .adm-dialog-footer{
    .adm-button{
      padding: .3rem 0 !important;
      font-size: .26rem !important;
    }
  }
}
@primary-color: #1DA57A;