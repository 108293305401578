@font:100rem;
@back:green;
.index{
  width: 100%;
  height: 100%;
  background-color: #171637;
  .topBackground{
    // position: absolute;
    width: 100%;
    // height: 3.8rem;
  }
  .publicList{
    width: 100%;
    // .copymarkWarp{
    //   position: fixed;
    //   bottom: 0;
    // }
    .copymark{
      display: flex;
      justify-content: center;
      // background-color: aqua;
      height: 15vh;
      align-items: flex-end;
      .trademark{
        opacity: .4;
        font-size: .16rem;
        font-weight: 400;
        color: #868686;
        line-height: .22rem;
        margin-bottom: .04rem;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
    .adm-list-item-description{
      font-size: .24rem
    }
    .avatorImg{
      .adm-image-img{
        width: .72rem;
        height: .72rem;
      }
    }
    .adm-list-card{
      margin: .26rem .99rem 0 .99rem;
      background: none;
      // .itembackground{
      //   border-radius: 10px;
      // }
      .adm-list-item{
        border-radius: 10px;
        margin-bottom: .12rem;
        // background-color: #ffffff;
        // background: url(../../image/indexImage/one.png);
        // background-size: 100% 100%;
        .adm-list-item-content{
          width: 100%;
          .adm-list-item-content-main{
            color: #1A1A1A;
            .adm-list-item-description{
              color: #A6A6A6;
              margin-top: .12rem;
            }
          }
          .adm-list-item-content-arrow{
            .adm-image{
              .adm-image-img{
                width: .38rem;
                height: .38rem;
              }
            }
          }
        }
      }
    }
    .adm-list-item:first-child{
      margin-bottom: .10rem;
      background: url(../../image/indexImage/one.png);
        background-size: 100% 100%;
    }
    .adm-list-item:nth-child(2){
      background: url(../../image/indexImage/two.png);
        background-size: 100% 100%;
    }
    .adm-list-item:nth-child(3){
      background: url(../../image/indexImage/tree.png);
        background-size: 100% 100%;
    }
    .adm-list-item:last-child{
      margin-bottom: .22rem;
      background: url(../../image/indexImage/four.png);
        background-size: 100% 100%;
    }
    
  }
  // .adm-dialog-body{
  //   padding-top: .3rem !important;
  // }
}
@primary-color: #1DA57A;