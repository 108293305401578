.rentPurchaseAward{
  .publicFormItem:last-child{
    --border-inner:none;
  }
}
.adm-cascader{
  height: 500px;
  .adm-cascader-header .adm-cascader-header-button{
    font-size: 16px;
    padding: 10px 12px;
  }
  .adm-cascader-header-title{
    font-size: 16px;
  }
  .adm-cascader-body{
    .adm-cascader-view-header-title{
      font-size: 16px;
      max-width: none;
    }
    .adm-tabs-tab-line{
      width: 1rem !important;
    }
    .adm-cascader-view-item{
      font-size: 16px;
    }
    .adm-check-list-item-extra{
      font-size: 16px;
    }
  }
}
.adm-cascader-body > .adm-cascader-view {
  --height: 430px;
}
@primary-color: #1DA57A;