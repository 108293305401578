.rankAward{
  .publicFormItem:last-child{
    --border-inner:none;
  }
  .infoTableItem{
    .adm-form {
      .adm-list{
        padding-top: .4rem;
        margin-top: -.4rem;
        // position: relative;
        // top: -.4rem;
      }
      
    }
    // padding: 2rem;
  }
  // .infoTableItem .adm-form .adm-list{
  //   border-radius: none !important;
  // }
  .awardIsEntry{
    position: relative;
    height: 1.6rem;
    background: #ECF1FF;
    border-radius: 30px 30px 0px 0px;
    .awardIsEntryText{
      font-size: .3rem;
      font-weight: 500;
      color: #363A44;
      padding: .3rem;
    }
    .awardIsEntryButton{
      div:first-child{
        margin-left: .28rem;
      }
      div{
        margin-left: .16rem;
      }
      .checkStyle{
        display: inline-block;
        width: 1.2rem;
        height: .5rem;
        text-align: center;
        line-height: .5rem;
        border-radius: .3rem;
        font-size: .28rem;
        background:#2C68FF;
        color: #ffffff;
      }
      .notCheckStyle{
        display: inline-block;
        width: 1.2rem;
        height: .5rem;
        text-align: center;
        line-height: .5rem;
        border-radius: .3rem;
        font-size: .28rem;
        background:#F5F5F5;
        color: "#4D4D4D"
      }
    }
  }
}
@primary-color: #1DA57A;