.employ{
  .styleRec{
    width: 100%;
    height: .4rem;
  }
  width: 100%;
  height: 2.46rem;
  padding-top: .4rem;
  background: linear-gradient(180deg, #2c68ff, #dee9ff 62%, #f7fbff);
    .adm-list-card{
      background: none;
      // padding: .4rem .5rem;
      .adm-list-item{
        background: #ffffff;
      }
      .adm-list-item-content{
        margin-left: .3rem;
        font-size: .34rem;
        padding: 18px 12px 12px 0;
        // .adm-list-item-content-main{
        //   margin-left: .3rem;
        // }
      }
    }
}
@primary-color: #1DA57A;