.talentMarket{
  .businessCard{
    background: #ffffff;
    margin-bottom: .2rem;
    .businessCardLf{
      width: .8rem;
      height: .8rem;
      margin-left: .3rem;
      margin-top: -.6rem;
      .adm-image{
        border-radius:50%;
        .adm-image-img{
          border-radius:50%; 
        }
      }
      
    }
    .businessCardRg{
      // height: 2rem;
      width: 6.4rem;
      // margin: .3rem;
      // box-shadow: 0px 2px 16px 0px rgba(130, 136, 150, 0.23);
      border-radius: 10px;
      // border: 1px solid #EEEEEE;
      .itemTopNull{
        height: .32rem;
      }
      .itemLineOne{
        height: .45rem;
        line-height: .45rem;
        margin: 0 .3rem;
        padding-top: .3rem;
        .OneTitle{
          font-size: .32rem;
          font-weight: 600;
          color: #1A1A1A;
        }
        .OneMoney{
          font-size: .3rem;
          color: #34E4AA;
        }
      }
      .itemLineTwo{
        font-size: .24rem;
        margin: 0 .3rem;
        margin-top: .3rem;
        .itemLineTwoLf{
          .TwoSpan{
            display: inline-block;
            padding: .08rem .15rem;
            background:#F5F5F5 ;
            margin-right: .1rem;
            color: #666666;
          }
        }
      }
      .itemLineThree{
        font-size: .24rem;
        margin: 0 .3rem;
        margin-top: .24rem;
        padding-bottom: .3rem;
        color: #808080;
        .ThreeSpan1{
          padding-right: .16rem;
          border-right: solid 1px #E6E6E6;
        }
        .ThreeSpan2{
          padding-left: .16rem;
        }
      }
      .itemLineTwoRg{
        max-width: 1.6rem;
        white-space:nowrap;/*强制文本在一行内显示*/
        text-overflow:ellipsis; /*溢出省略号，支持ie、safari（webkit）*/
        -o-text-overflow:ellipsis; /*溢出省略号，支持opera*/
        overflow:hidden;/*溢出隐藏*/
        // display: inline-block;
        margin: 0.04rem .3rem;
        // margin-top: .16rem;
        // margin-left: .3rem;
        // text-align: right;
        // vertical-align: top;
        font-size: .22rem;
        color: #B3B3B3;
      }
    }
  }
  
}
@primary-color: #1DA57A;