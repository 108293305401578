.publicSearchBar{
  padding: 0 .3rem;
  .adm-search-bar-input-box{
    height: .6rem;
    border-radius: .3rem;
    border: solid .01rem #2C68FF;
    .antd-mobile-icon{
      margin-left: .19rem;
      margin-right: .07rem;
      font-size: .24rem;
      color: #989898;
    }
    .adm-input-wrapper{
      height: .6rem;
      padding: 0;
      color: #B3B3B3;
      .adm-input{
        height: .6rem;
        padding: 0;
        font-size: .26rem;
      }
    }
  }
}
@primary-color: #1DA57A;