.infoTableHeader{
  padding: .3rem 0;
  text-align: center;
  .infoTableHeaderTitle{
    font-size: .36rem;
    font-weight: 500;
    color: #363A44;
  }
  .infoRemind{
    margin-top: .1rem;
    font-size: .24rem;
    .infoRemindAsterisk{
      color: #F93A42;
      padding-right: .05rem;
    }
    .infoRemindContent{
      color: #34E4AA;
    }
  }
}

@primary-color: #1DA57A;