.collection{
  .adm-list-item-content{
    border-bottom:none;
    padding:none !important;
  }
  .collectionType{
    margin-top: .3rem;
    font-size: .3rem;
    margin-left: .4rem;
  }
  .activeCollection{
    margin-left: .2rem;
    display: inline-block;
    width: 2.5rem;
    height: .7rem;
    background: #2C68FF;
    border-radius: .35rem;
    color: #FFFFFF;
    line-height: .7rem;
    text-align: center;
  }
  .activeDefault{
    margin-left: .2rem;
    display: inline-block;
    width: 2.5rem;
    height: .7rem;
    background: #FFFFFF;
    color: #808080;
    border-radius: .35rem;
    border: 1px solid #EEEEEE;
    line-height: .7rem;
    text-align: center;
  }
  .questionType{
    margin: .3rem;
  }
}
@primary-color: #1DA57A;